$base-font-family: "Charis SIL", serif;
// Width of the content area
$content-width:    40em;

@import "minima";

/** Make tag links in the index not demand all the attention */
.post-meta {
  a {
    /* Colour is from the minima theme */
    color: #828282;
  }
}

/**
 * Tables (added by ward 2015-02-09)
 */
table {
  border-collapse: collapse;
  tr:nth-child(2n) {
    background-color: $grey-color-light;
  }
  td {
    padding: 0.3em;
  }
  &#bxlruns {
    font-size: $small-font-size;
    td {
      &.date {
        white-space: nowrap;
      }
      &.distance {
        text-align: right;
      }
    }
  }
  &#results {
    td {
      &.date {
        white-space: nowrap;
      }
      &.distance, &.time, &.agegrade {
        text-align: right;
      }
    }
  }
  &#personalrecords {
    td {
      &.time {
        text-align: right;
      }
    }
    th {
      &.time {
        text-align: right;
      }
    }
  }
}

div#results-wrapper, div#personalrecords-wrapper {
  overflow-x: scroll;
}

div.post-content figure {
  img {
    margin: 0 auto;
  }
  figcaption {
    text-align: center;
  }
}

/**
 * Input styling for JavaScript client side calculators
 */
input {
  border: 1px solid #e8e8e8;
  border-radius: 0.5em;
  padding: 0.1em 0.6em;
  &.narrow {
    width: 4em;
  }
}

.post-content {
  p {
    text-align: justify;
  }
}

/**
 * Remove the black bar at the top of the site
 */
.site-header {
  border-top: 0px;
}

/**
 * Style the kramdown table of contents
 */
#markdown-toc {
  float: right;
  padding-left: 2em;
  margin-left: 1em;
  list-style-type: upper-roman;

  ol {
    list-style-type: lower-roman;
  }
}

/**
 * Style the race report splits. See _include/racesplit.html.
 */
.racesplit {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}
.racesplit-icon {
  width: 10%;
  font-size: 2em;
  text-align: left;
}
.racesplit-content {
  width: 90%;
  font-style: italic;
  font-size: 1.1em;
  color: grey;
  text-align: justify;
}

/**
 * Style plans
 */
/* Colour variables */
$repetition: #f0e442;
$endurance: #0072b2;
$easy: #56b4e9;
$threshold: #009e73;
$interval: #e69f00;
$race: #ff00ff;
